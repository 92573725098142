'use client';

import {useLocale} from 'next-intl';
import { useTranslations } from 'next-intl';
import React, { useState } from 'react';
import { subscribeToEmailOctopus } from '@/api-medentic/subscibe';
import SecondStepSubscriptionModal from '@/app-components/page/newsletter/SecondStepSubscriptionModal';

export default function NewsletterCardForm({ cta, inputPlaceholder }: { cta: string; inputPlaceholder: string }) {
	const t = useTranslations('components.newsletter');

	const [regId, setRegId] = useState<string | null>(null);
	const [email, setEmail] = useState<string>('');
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [successMessage, setSuccessMessage] = useState<string | null>(null);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const locale = useLocale();

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		setIsSubmitting(true);

		const result = await subscribeToEmailOctopus({
			locale: locale,
			email_address: email,
			firstName: '',
			lastName: '',
		});

		if (result.success) {
			setRegId(result.registerId);
			setSuccessMessage(result.message);
			setErrorMessage(null);
			setIsModalOpen(true);
		} else {
			setRegId(null);
			setErrorMessage(result.message);
			setSuccessMessage(null);
			setIsModalOpen(false);
		}

		setIsSubmitting(false);
	};

	return (
		<div className="bg-light border rounded-2 p-2 mb-2">
			{successMessage ? (
				<div className="alert alert-success m-0">{successMessage}</div>
			) : (
				<form className="input-group" onSubmit={handleSubmit}>
					<input className="form-control form-control-lg focus-shadow-none bg-light border-0 me-1" type="email" placeholder={inputPlaceholder} value={email} onChange={(e) => setEmail(e.target.value)} required />
					<button type="submit" className="btn btn-lg btn-dark rounded-2 mb-0" disabled={isSubmitting}>
						{isSubmitting ? t('cta_submitting') : cta}
					</button>
				</form>
			)}
			{errorMessage && <div className="text-danger ml-2 mt-2">{errorMessage}</div>}

			{isModalOpen && <SecondStepSubscriptionModal locale={locale} show={isModalOpen} email={email} regId={regId || ''} onClose={() => setIsModalOpen(false)} />}
		</div>
	);
}
