import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"cyrillic\",\"latin\"],\"weight\":[\"400\",\"500\",\"600\",\"700\"],\"variable\":\"--font-inter\",\"display\":\"swap\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"Rethink_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"500\",\"600\",\"700\"],\"style\":[\"normal\",\"italic\"],\"variable\":\"--font-rethink-sans\",\"display\":\"swap\"}],\"variableName\":\"rethinkSans\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app-components/layout/header/header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app-components/providers/context-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app-components/providers/gtm-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PostHogProvider"] */ "/vercel/path0/src/app-components/providers/PostHogProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app-components/providers/themes-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app-components/tracking/GoogleAnalytics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app-components/tracking/GTM.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/scss/style.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/vendor/font-awesome/css/all.min.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/vendor/bootstrap-icons/bootstrap-icons.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/vendor/glightbox/css/glightbox.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/vendor/swiper/swiper-bundle.min.css");
