'use client';

import React from 'react';

import { GTMProvider as Provider } from '@elgorditosalsero/react-gtm-hook';

const gtmParams = {
	id: process.env.NEXT_PUBLIC_GTM_ID ?? '',
	dataLayerName: 'customDataLayerName',
};

function GTMProvider({ children }: { children: React.ReactNode }) {
	return <Provider state={gtmParams}>{children}</Provider>;
}

export default GTMProvider;
