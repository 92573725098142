'use client';

import React, { useContext, useEffect } from 'react';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { AppContext } from '@/app-components/providers/context-provider';

export default function GTM() {
	const contextValues = useContext(AppContext);
	const sendDataToGTM = useGTMDispatch();

	useEffect(() => {
		if (contextValues?.base?.appVisited) {
			return;
		}
		if (process.env.NODE_ENV === 'production') {
			sendDataToGTM({
				event: 'appLoaded',
				email: '',
			});
		}

		contextValues?.updateBase({ key: 'appVisited', value: true });
	}, [sendDataToGTM, contextValues]);

	return <></>;
}
