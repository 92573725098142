'use client';

import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { updateSubscribedEmailOctopus } from '@/api-medentic/subscibe';
import { useTranslations } from 'next-intl';

interface CustomModalProps {
	locale: string;
	email: string;
	regId: string;
	show: boolean;
	onClose: () => void;
}

const SecondStepSubscriptionModal: React.FC<CustomModalProps> = ({ locale, email, regId, show, onClose }) => {
	const t = useTranslations('components.newsletter');

	const [formData, setFormData] = useState({
		email,
		firstName: '',
		lastName: '',
	});
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [successMessage, setSuccessMessage] = useState<string | null>(null);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		setIsSubmitting(true);

		try {
			const response = await updateSubscribedEmailOctopus({
				locale: locale,
				memberId: regId,
				email_address: formData.email,
				firstName: formData.firstName,
				lastName: formData.lastName,
			});

			if (response.success) {
				setSuccessMessage(response.message);
				setErrorMessage(null);
				onClose();
			} else {
				setErrorMessage(response.message);
				setSuccessMessage(null);
			}
		} catch (error) {
			setErrorMessage('An unexpected error occurred.');
		}

		setIsSubmitting(false);
	};

	return (
		<Modal show={show} onHide={onClose} centered>
			<Modal.Header closeButton>
				<Modal.Title>{t('modal.title')}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={handleSubmit}>
					<Form.Group className="mb-3">
						<Form.Label>{t('modal.email')}</Form.Label>
						<Form.Control type="email" name="email" value={formData.email} readOnly />
					</Form.Group>

					<Form.Group className="mb-3">
						<Form.Label>{t('modal.first_name')}</Form.Label>
						<Form.Control type="text" name="firstName" value={formData.firstName} onChange={(e) => setFormData({ ...formData, firstName: e.target.value })} required />
					</Form.Group>

					<Form.Group className="mb-3">
						<Form.Label>{t('modal.last_name')}</Form.Label>
						<Form.Control type="text" name="lastName" value={formData.lastName} onChange={(e) => setFormData({ ...formData, lastName: e.target.value })} required />
					</Form.Group>

					{errorMessage && <div className="text-danger">{errorMessage}</div>}
					{successMessage && <div className="text-success">{successMessage}</div>}

					<Button type="submit" variant="primary" disabled={isSubmitting} className="w-100">
						{isSubmitting ? t('modal.cta_submitting') : t('modal.cta')}
					</Button>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

export default SecondStepSubscriptionModal;
